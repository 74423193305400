/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { NavigationEnd, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import * as Sentry from '@sentry/angular'
import {
  IntegrationEvent,
  IntegrationReadyEvent,
} from 'models/integration-event'
import { EMPTY, Observable, forkJoin, of } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators'
import { deserialise } from 'utils/json-utils'
import { environment } from '../environments/environment'
import * as auth from './actions/auth'
import * as organization from './actions/organization'
import { UsersReceiveAction } from './actions/shared'
import * as userSettings from './actions/userSettings'
import {
  AnalyticsEvents,
  dataFromPathVariables,
  getViewNameByUrl,
} from './constants/analytics'
import { FeatureFlag } from './constants/feature-flags'
import { HubtypeOrganizationComplete } from './models/hubtype-organization'
import { HubtypeUser } from './models/hubtype-user'
import { OAuth } from './models/oauth'
import * as fromRoot from './reducers'
import { CaseList } from './reducers/desk.state'
import * as fromRootUserSettings from './reducers/user-settings.state'
import { IntentsGuard } from './services/ai-intent.guard'
import { KnowledgeGuard } from './services/ai-knowledge.guard'
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service'
import { CaseArchiveGuard } from './services/case-archive.guard'
import { ColorSchemeService } from './services/color-scheme.service'
import { DesktopNotifications } from './services/desktop-notifications'
import { FeedbackService } from './services/feedback.service'
import { CaseService } from './services/hubtype-api/case.service'
import { OrganizationService } from './services/hubtype-api/organization.service'
import { UserService } from './services/hubtype-api/user.service'
import { IdleGuardService } from './services/idle-guard.service'
import { IntegrationsService } from './services/integrations.service'
import { PusherService } from './services/pusher.service'
import { AngularComponent } from './shared/AngularComponent'
import { AwayDialogComponent } from './shared/away-dialog/away-dialog.component'
import { FeedbackComponent } from './shared/shared-brand/feedback/feedback.component'
import { Analytics } from './utils/analytics'
import { TempNewKnowledgeSourcesGuard } from './services/temp-ai-new-knowledge-sources.guard'
import { HubtypeAIUrl } from './constants'

declare global {
  interface Window {
    userGuiding: any
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AngularComponent implements OnInit {
  private userGuideIdentified = false

  public $hasAiAccess: Observable<boolean>
  public $hasAiIntentsAccess: Observable<boolean>
  public $hasAiKnowledgeAccess: Observable<boolean>
  public $hasAiTempNewKnowledgeSourcesGuard: Observable<boolean>
  public $hasCaseArchiveAccess: Observable<boolean>
  public aiMainLink = ''
  public intentModelsLink = HubtypeAIUrl.IntentModels
  public knowledgeBaseLink = HubtypeAIUrl.KnowledgeBase
  public knowledgeBaseTempLink = HubtypeAIUrl.TempKnowledgeBase
  public auth: any = {}

  public dialogRef: MatDialogRef<any>
  public hasAnalyticsAccess = false
  public hasCampaignsAccess = false
  integrationsListener: Observable<IntegrationEvent>
  public isAgent = false
  isCollapse = true
  public isFullApp = !environment.minimalVersion
  public me: HubtypeUser
  public organization: HubtypeOrganizationComplete
  public splash = true

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public feedbackBar: MatSnackBar,
    private feedbackService: FeedbackService,
    @Inject('desktopNotifications')
    private desktopNotification: DesktopNotifications,
    @Inject('organizationService') private orgService: OrganizationService,
    // Not used on component, but needs the injection to work
    @Inject('pusherService') private pusherService: PusherService,
    @Inject('userService') private userService: UserService,
    @Inject('idleGuardService') private idleGuardService: IdleGuardService,
    private colorSchemeService: ColorSchemeService,
    private integrationsService: IntegrationsService,
    @Inject('caseService') private caseService: CaseService,
    private caseArchiveGuard: CaseArchiveGuard,
    private aiKnowledgeGuard: KnowledgeGuard,
    private aiTempNewKnowledgeSourcesGuard: TempNewKnowledgeSourcesGuard,
    private aiIntentsGuard: IntentsGuard,
    private breadcrumbService: BreadcrumbService
  ) {
    super()
    this.colorSchemeService.load()
  }

  _buildDialog() {
    this.dialogRef = this.dialog.open(AwayDialogComponent, {
      width: '460px',
    })
    this.dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((keepWorking: boolean) => {
        if (!keepWorking) {
          this.userService
            .setAvailable()
            .pipe(
              first(),
              finalize(() => this.ref.markForCheck())
            )
            .subscribe(user => {
              this.store.dispatch(new organization.UpdateUserAction(user))
            })
        }
      })
  }

  loadAuth() {
    const queryParams = new URLSearchParams(window.location.search)
    const access_token = queryParams.get('access_token')
    const refresh_token = queryParams.get('refresh_token')
    if (access_token && refresh_token) {
      this.store.dispatch(
        new auth.LoginAction(
          deserialise(
            { access_token, refresh_token, token_type: 'Bearer' },
            OAuth
          )
        )
      )
    }

    const auths = this.store
      .select(fromRoot.getAuthState)
      .pipe(filter(auth => !auth || !auth.oauth))

    this.subscribeUntilDestroy(auths, auth => {
      this.me = null
      this.splash = false
      this.ref.markForCheck()
    })
  }

  loadCases() {
    //Initial cases loading
    this.store
      .select(fromRoot.isLoggedIn)
      .pipe(filter(isLogged => isLogged === true))
      .subscribe(() => {
        this.store
          .select(fromRoot.getPusherConnectedStatus)
          .pipe(first(Boolean))
          .subscribe(() => {
            this.caseService.loadCasesInParallel(CaseList.ATTENDING)
            this.caseService.loadCasesInParallel(CaseList.IDLE)
            this.caseService.loadCasesInParallel(CaseList.WAITING)
          })
      })
  }

  loadOrganization() {
    const hubtypeOrganizationCompletes = this.store
      .select(fromRoot.getAuthState)
      .pipe(
        filter(auth => Boolean(auth && auth.oauth && !auth.error && !this.me)),
        switchMap((auth: any) => {
          this.ref.markForCheck()
          return this.userService.getMe(true).pipe(
            catchError(err => {
              this.logout('An error ocurred, please try again later')
              return EMPTY
            })
          )
        }),
        switchMap((me: HubtypeUser) => {
          if (environment.sentryURL) {
            Sentry.configureScope(scope => {
              scope.setUser({ id: me.id })
            })
          }
          this.ref.markForCheck()
          this.splash = true
          if (me.isOffline) {
            return this.userService.setOnline(me).pipe(
              catchError(err => {
                // Error 409 occurs when the user was not in offline status
                if (err?.status === 409) {
                  return of(me)
                }
                this.logout('An error occurred setting online the user')
              })
            )
          }
          return of(me)
        }),
        switchMap((me: HubtypeUser) => {
          setTimeout(() => Analytics.user(me.id, me), 300)
          if (this.desktopNotification.isPermissionDefaul()) {
            this.desktopNotification.allowNotifications().then(r => {
              if (r === 'denied') {
                me.notifications_new_case = false
                me.notifications_new_message = false
              }
            })
          }
          this.store.dispatch(new auth.SetMeAction(me))
          this.store.dispatch(new organization.UpdateUserAction(me))
          if (me.isAway) {
            this._buildDialog()
          }
          this.ref.markForCheck()
          this.splash = true
          return this.orgService.getAndAssembleHubtypeOrganization()
        })
      )

    this.subscribeUntilDestroy(
      hubtypeOrganizationCompletes,
      (org: HubtypeOrganizationComplete) => {
        this.ref.markForCheck()
        if (org) {
          this.organization = org
          this.userService.setTranslationsAccess(this.organization.simplified)

          this.hasCampaignsAccess =
            this.organization.simplified.hasAccessTo(FeatureFlag.CAMPAIGNS) &&
            this.me.is_admin

          this.isAgent = this.me.is_agent

          this.hasAnalyticsAccess =
            this.me.analytics_access && (this.me.is_admin || this.me.is_manager)

          this.$hasAiKnowledgeAccess = this.aiKnowledgeGuard
            .canActivate()
            .pipe(first())

          this.$hasAiTempNewKnowledgeSourcesGuard =
            this.aiTempNewKnowledgeSourcesGuard.canActivate().pipe(first())

          this.$hasAiIntentsAccess = this.aiIntentsGuard
            .canActivate()
            .pipe(first())

          this.$hasAiAccess = forkJoin([
            this.$hasAiKnowledgeAccess,
            this.$hasAiIntentsAccess,
            this.$hasAiTempNewKnowledgeSourcesGuard,
          ]).pipe(
            map(([knowledgeAccess, intentsAccess, tempNewKnowledgeAccess]) => {
              this.aiMainLink = this.computeAIMainLink({
                knowledgeAccess,
                intentsAccess,
                tempNewKnowledgeAccess,
              })
              return (
                (knowledgeAccess || intentsAccess) &&
                this.me?.is_admin &&
                !this.me?.must_change_password
              )
            })
          )

          const analytics = (window as any).analytics
          if (analytics) {
            analytics.group(this.organization.id, {
              name: this.organization.name,
            })
          }
        }
        this.store.dispatch(new UsersReceiveAction(org.users))
        this.store.dispatch(
          new organization.SetOrganizationAction({
            organization: org,
            meId: this.me ? (this.me.id as string) : '',
          })
        )
        this.splash = false
      },
      err => {
        console.log(err)
        if (err.status === 403) {
          this.logout('You do not have permission to login')
        } else {
          this.logout('Server not responding, please try again later')
        }
      }
    )
  }

  computeAIMainLink({
    knowledgeAccess,
    intentsAccess,
    tempNewKnowledgeAccess,
  }: {
    knowledgeAccess: boolean
    intentsAccess: boolean
    tempNewKnowledgeAccess: boolean
  }) {
    if (intentsAccess) return HubtypeAIUrl.IntentModels
    if (knowledgeAccess && tempNewKnowledgeAccess)
      return HubtypeAIUrl.TempKnowledgeBase
    return knowledgeAccess && HubtypeAIUrl.KnowledgeBase
  }

  logout(error_msg?: string) {
    this.userService
      .setOffline()
      .pipe(
        first(),
        finalize(() => {
          this.store.dispatch(new auth.LogoutAction())
          this.ref.markForCheck()
          if (error_msg) {
            this.router.navigate(['/sign-in', { error: error_msg }])
          } else {
            this.router.navigate(['/sign-in'])
          }
        })
      )
      .subscribe()
  }

  ngOnInit() {
    this.idleGuardService.handleUserIdle()
    let previousUrl = ''
    const changed = this.router.events.pipe(
      distinctUntilChanged((previous: any, current: any) => {
        if (current instanceof NavigationEnd) {
          previousUrl = previous.url
          return previous.url === current.url
        }
        return true
      })
    )
    this.subscribeUntilDestroy(changed, (x: any) => {
      const url = this.router.url
      const name = getViewNameByUrl(url)
      const data = dataFromPathVariables(url) || {}
      if (this.me) {
        setTimeout(() => Analytics.user(this.me.id, this.me), 300)
      }
      Analytics.view(name, { data, ...{ referrer: previousUrl } })
    })

    this.feedbackService.observe(
      feedback => {
        if (feedback.isError() && feedback.error) {
          console.error(feedback.error)
        }
        this.feedbackBar.openFromComponent(FeedbackComponent, {
          ...feedback.config,
          data: feedback,
        })
      },
      err => console.error(err)
    )

    this.store
      .select(fromRoot.getMeState)
      .pipe(
        filter(me => Boolean(me)),
        tap(me => {
          this.identifyUserGuiding(me)
        })
      )
      .subscribe(me => {
        this.me = me
      })

    this.store
      .select(fromRootUserSettings.isNavBarCollapse)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isCollapse => {
        this.isCollapse = isCollapse
      })

    this.loadAuth()
    this.loadIntegrations()
    this.loadOrganization()
    this.loadCases()
    this.setMenuVisibility()
  }

  setMenuVisibility() {
    const organization$ = this.store.select(fromRoot.getOrganization)
    organization$
      .pipe(
        filter(organization => Boolean(organization)),
        first()
      )
      .subscribe(org => {
        this.$hasCaseArchiveAccess = this.caseArchiveGuard
          .canActivate()
          .pipe(first())
      })
  }

  oldReportingVisibilityTemporary() {
    if (this.me?.is_agent) return false
    const orgsWithVisibility = [
      '28d3635a-4e22-4f83-8f5b-5ebd77181013',
      '31de9510-ecc4-4bd2-8dc2-eb154bf5599b',
      '3215950a-01db-4271-b554-6ca265b1dc21',
      '479a0e5e-2b87-4515-97d7-0a6cd32bea45',
      '0c622c1b-cf4d-4cac-96a0-235c16b3ce98',
      'b4083543-9379-4fc1-8951-8ab51199f966',
    ]
    if (orgsWithVisibility.includes(this.me?.organization_id)) return true
    let oldReportingFeatFlag
    this.store
      .select(fromRoot.getOrganization)
      .pipe(
        filter(organization => Boolean(organization)),
        map(
          organization =>
            organization.feature_flags_json[
              FeatureFlag.DISPLAY_OLD_REPORTING_TEMPORARY
            ]
        ),
        first()
      )
      .subscribe(flag => {
        oldReportingFeatFlag = flag
      })
    return oldReportingFeatFlag
  }

  toggleAway() {
    if (!this.me.isAway) {
      this.userService
        .setAway()
        .pipe(
          first(),
          finalize(() => this.ref.markForCheck())
        )
        .subscribe(user => {
          this.store.dispatch(new organization.UpdateUserAction(user))
        })
    }
    this._buildDialog()
  }

  toogleCollapse() {
    Analytics.event(AnalyticsEvents.NAVBAR_COLLAPSE_UNCOLLAPSE, {
      collapse: !this.isCollapse,
    })
    this.store.dispatch(new userSettings.ToogleNavbarCollapseAction())
  }

  private identifyUserGuiding(user: HubtypeUser) {
    if (!this.userGuideIdentified && user && window.userGuiding) {
      // eslint-disable-next-line no-console
      console.info('Identifying user for UserGuiding')
      window.userGuiding.identify(user.id, {
        role: user.role,
        organizationId: user.organization_id,
        hasAnalyticsAccess: user.analytics_access,
        hasTranslationService: user.has_translation_service,
      })
    }
  }

  private loadIntegrations() {
    if (this.integrationsService.isUsingIntegrations()) {
      this.integrationsService.sendEvent(new IntegrationReadyEvent())

      this.integrationsService
        .onEventReceived()
        .pipe(
          filter(ev => ev.type === 'init'),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(ev => {
          const caseId = ev.payload?.caseId
          let resourceId: string = ev.payload?.resourceId // /resource/{namespace_id}/hubtypechat

          if (!caseId) {
            console.error('Integration invoked without a `caseId` present')
            return
          }
          if (!resourceId) {
            console.error('Integration invoked without a `resourceId` present')
            return
          }
          resourceId = resourceId.replace('resource', 'lcc') + '/'
          console.log('Integration ready')

          setTimeout(() => {
            this.router.navigate(['desk', 'inbox', caseId])
          }, 500)
        })
    }
  }
}
