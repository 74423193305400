import {
  HttpClient,
  HttpParams,
  HttpUrlEncodingCodec,
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { IntegrationAuthEvent } from 'models/integration-event'
import { Observable, throwError as observableThrowError, timer } from 'rxjs'
import { catchError, first, map, share, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import * as auth from '../../actions/auth'
import { UpdateUserAction } from '../../actions/organization'
import { HubtypeUser } from '../../models/hubtype-user'
import { FlowBuilderOAuth, OAuth } from '../../models/oauth'
import * as fromRoot from '../../reducers'
import { ConverterService } from '../converter.service'
import { IntegrationsService } from '../integrations.service'
import { LoggerService } from '../logger.service'
import { HubtypeApiService } from './hubtype-api.service'

/* eslint-disable @typescript-eslint/naming-convention */

// encode working for '+' in Url

export class CustomQueryEncoderHelper extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    k = super.encodeKey(k)
    return k.replace(/\+/gi, '%2B')
  }
  encodeValue(v: string): string {
    v = super.encodeValue(v)
    return v.replace(/\+/gi, '%2B')
  }
}

@Injectable()
export class AuthService {
  private oauth$
  private refresh$
  private LAST_REFRESH_TOKEN_KEY = 'lastRefreshToken'
  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    @Inject('convertService') private convertService: ConverterService,
    private integrationsService: IntegrationsService,
    @Inject('apiService') private apiService: HubtypeApiService,
    private loggerService: LoggerService
  ) {
    this.oauth$ = this.store.select(fromRoot.getAccessTokenState)
  }

  login(username: string, password: string): Observable<any> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        username,
        password,
        grant_type: 'password',
        client_id: environment.client_id,
      },
      encoder: new CustomQueryEncoderHelper(),
    })
    return this.http
      .post(`${environment.baseURL}/o/token/`, params, {
        withCredentials: environment.currentUserCookieEnabled,
      })
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(response, OAuth)
        ),
        catchError(error => {
          this.loggerService.loggHttpError('POST', error)
          return observableThrowError(error)
        })
      )
  }

  loginWithOneTimeToken(token: string): Observable<any> {
    return this.http
      .post(
        `${environment.baseURL}/v1/authentication/one_time_token_login`,
        {
          token,
        },
        { withCredentials: environment.currentUserCookieEnabled }
      )
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(response, OAuth)
        ),
        catchError(error => {
          this.loggerService.loggHttpError('POST', error)
          return observableThrowError(error)
        })
      )
  }

  refreshSession(): Observable<OAuth> {
    const _makeCallRefreshToken = (
      refreshParams: HttpParams
    ): Observable<OAuth> =>
      this.http
        .post(`${environment.baseURL}/o/token/`, refreshParams, {
          withCredentials: environment.currentUserCookieEnabled,
        })
        .pipe(
          map(response =>
            this.convertService.jsonConvert.deserializeObject(response, OAuth)
          )
        )

    const _refreshTokenCallParamsFactory = (refresToken: string): HttpParams =>
      new HttpParams({
        fromObject: {
          callback: 'none',
          grant_type: 'refresh_token',
          refresh_token: refresToken,
          client_id: environment.client_id,
        },
      })

    if (this.refresh$) {
      return this.refresh$
    }

    let refresh_token = null
    this.oauth$.subscribe(oauth => {
      refresh_token = oauth ? oauth.refresh_token : null
    })
    if (!refresh_token) {
      return observableThrowError({
        detail: 'Can not refresh session without refreshtoken',
      })
    }

    this.refresh$ = _makeCallRefreshToken(
      _refreshTokenCallParamsFactory(refresh_token)
    ).pipe(
      catchError(err =>
        // delay added to give enough the other tab to refresh last refresh token on localstorage
        timer(200).pipe(
          first(),
          switchMap(() => {
            if (err.status !== 400) {
              return observableThrowError(
                'Critical: Unkown error on the first try refreshing the token'
              )
            }
            console.log(
              'Error on the first try refreshing the token probably due to several tabs open'
            )
            const lastRefreshToken = localStorage.getItem(
              this.LAST_REFRESH_TOKEN_KEY
            )
            if (lastRefreshToken && refresh_token !== lastRefreshToken) {
              // After one error trying to refresh the token we try again with the refresh token on the special key of the local storage 'lastRefreshToken'
              // this refresh token should be the most updated one independently on the browser tab
              return _makeCallRefreshToken(
                _refreshTokenCallParamsFactory(lastRefreshToken)
              ).pipe(
                catchError(error => {
                  console.log(error)
                  return observableThrowError(
                    'Critical: Unkown error on the second try of refresh the token'
                  )
                })
              )
            }
          })
        )
      ),
      share()
    )

    if (this.integrationsService.isUsingIntegrations()) {
      //Integration activated
      this.refresh$.subcribe(oauth => {
        const event = new IntegrationAuthEvent(oauth)
        this.integrationsService.sendEvent(event)
      })
    }
    this.refresh$.subscribe(
      oauth => {
        localStorage.setItem(this.LAST_REFRESH_TOKEN_KEY, oauth?.refresh_token)
        return this.store.dispatch(new auth.LoginAction(oauth))
      },
      err => this.logout(null, 'Session expired. Please, log in again.'),
      () => (this.refresh$ = null)
    )

    return this.refresh$
  }

  getFlowbuilderToken(botID: string): Observable<FlowBuilderOAuth> {
    return this.apiService
      .post(
        '/authentication/flow_builder_token/',
        {
          bot_id: botID,
        },
        null,
        'v1'
      )
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            FlowBuilderOAuth
          )
        )
      )
  }

  logout(user?: HubtypeUser, error_msg?: string) {
    this.store.dispatch(new auth.LogoutAction())
    if (user) {
      this.store.dispatch(new UpdateUserAction(user))
    }
    if (error_msg) {
      this.router.navigate(['/sign-in', { error: error_msg }])
    } else {
      this.router.navigate(['/sign-in'])
    }
  }
}
