import buildVersion from '../assets/json/build-info.json'

export const environment = {
  production: true,
  minimalVersion: false, // version to be embedded in a CRM
  version: buildVersion.version,

  google_app_id:
    '648141894523-s1rvdd3o3498cl8bt81r9quapjgclg0o.apps.googleusercontent.com', // pragma: allowlist secret
  maps_api: 'AIzaSyDkMEfW7VXVlWMi1NStIgNDPqrhlsZrNrk', // pragma: allowlist secret
  ssoUrl: null,
  sentryURL:
    'https://6c86eb9effb3420180ea42f6d554fc1b@o368614.ingest.sentry.io/5202728',
  // feature flags
  currentUserCookieEnabled: true,
  hardcodedInsightsFilters: false,
  supportMail: 'support@hubtype.com',
  userGuidingContainerId: null,
}
