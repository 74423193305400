<app-splash [splash]="splash"></app-splash>

<ng-container
  *ngIf="me && isFullApp && !me.must_change_password; else withoutNavbar"
>
  <div fxFlex="grow" fxLayout="row" class="mat-typography" *ngIf="!splash">
    <div [ngClass]="isCollapse ? 'collapse' : ''" class="ht-nav">
      <!--Menu sidebar mid-->
      <div class="ht-nav-sub">
        <div class="ht-nav-menu">
          <!--Collapse-->
          <div class="ht-nav-item">
            <div (click)="toogleCollapse()" class="ht-btn-collapse">
              <i
                [ngClass]="isCollapse ? 'fa-chevron-right' : 'fa-chevron-left'"
                class="fa-solid"
              ></i>
              <i
                [ngClass]="isCollapse ? 'fa-chevron-right' : 'fa-chevron-left'"
                class="fa-solid"
              ></i>
              <span *ngIf="!isCollapse">Collapse</span>
            </div>
          </div>

          <!--Bot-->
          <div
            class="ht-nav-item"
            *ngIf="me?.is_admin && !me?.must_change_password"
          >
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              routerLink="/bots"
            >
              <i class="fa-solid fa-robot"></i>
              <label>Bot</label>
            </div>
            <div class="ht-nav-list">
              <a routerLinkActive="active" routerLink="/bots/all">Bots</a>
            </div>
          </div>

          <!--AI-->
          <div class="ht-nav-item" *ngIf="$hasAiAccess | async">
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              [routerLink]="aiMainLink"
            >
              <i class="fa-solid fa-brain"></i>
              <label>A.I.</label>
            </div>
            <div class="ht-nav-list">
              <a
                *ngIf="$hasAiIntentsAccess | async"
                [routerLink]="intentModelsLink"
                routerLinkActive="active"
                >Intents models</a
              >
              <a
                *ngIf="
                  ($hasAiKnowledgeAccess | async) &&
                  ($hasAiTempNewKnowledgeSourcesGuard | async) === false
                "
                [routerLink]="knowledgeBaseLink"
                routerLinkActive="active"
              >
                Knowledge base
              </a>
              <a
                *ngIf="
                  ($hasAiKnowledgeAccess | async) &&
                  ($hasAiTempNewKnowledgeSourcesGuard | async)
                "
                [routerLink]="knowledgeBaseTempLink"
                routerLinkActive="active"
              >
                Knowledge base
              </a>
            </div>
          </div>

          <!--Desk-->
          <div class="ht-nav-item">
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              routerLink="/desk"
            >
              <i class="fa-solid fa-headset"></i>
              <label>Helpdesk</label>
            </div>
            <div class="ht-nav-list">
              <a routerLinkActive="active" routerLink="/desk/inbox">Inbox</a>
              <a routerLinkActive="active" routerLink="/desk/dashboard"
                >Realtime Dashboard</a
              >
              <a
                *ngIf="$hasCaseArchiveAccess | async"
                routerLinkActive="active"
                routerLink="/desk/archive"
                >Case Archive</a
              >
              <a
                *ngIf="oldReportingVisibilityTemporary()"
                routerLinkActive="active"
                routerLink="/desk/reporting"
                >Reporting</a
              >
            </div>
          </div>

          <!--Campaigns-->
          <div
            class="ht-nav-item"
            *ngIf="hasCampaignsAccess && !me?.must_change_password"
          >
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              routerLink="/campaigns"
            >
              <i class="fa-solid fa-flag"></i>
              <label>Campaigns</label>
            </div>
            <div class="ht-nav-list">
              <a routerLink="/campaigns/manage" routerLinkActive="active"
                >Campaigns List</a
              >
              <a routerLink="/campaigns/contacts" routerLinkActive="active"
                >Contacts</a
              >
            </div>
          </div>

          <!--Analytics-->
          <div
            class="ht-nav-item"
            *ngIf="!isAgent && !me?.must_change_password"
          >
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              routerLink="/analytics"
            >
              <i class="fa-solid fa-chart-simple"></i>
              <label>Analytics</label>
            </div>
            <div class="ht-nav-list">
              <a routerLink="/analytics/overview" routerLinkActive="active"
                >Overview</a
              >
              <a
                *ngIf="hasAnalyticsAccess"
                routerLink="/analytics/projects"
                routerLinkActive="active"
                >Projects</a
              >
              <a
                *ngIf="hasAnalyticsAccess"
                routerLink="/analytics/agents"
                routerLinkActive="active"
                >Agents</a
              >
              <a
                *ngIf="hasAnalyticsAccess"
                routerLink="/analytics/bots"
                routerLinkActive="active"
                >Bots</a
              >
            </div>
          </div>

          <!--Organization-->
          <div class="ht-nav-item" *ngIf="!me?.is_agent">
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              routerLink="/organization"
            >
              <i class="fa-solid fa-gear"></i>
              <label>Organization Settings</label>
            </div>
            <div class="ht-nav-list">
              <a routerLink="/organization/edit" routerLinkActive="active"
                >Overview</a
              >
              <a routerLink="/organization/accounts" routerLinkActive="active"
                >Accounts</a
              >
              <a routerLink="/organization/projects" routerLinkActive="active"
                >Projects</a
              >
              <a routerLink="/organization/webhooks" routerLinkActive="active"
                >Webhooks</a
              >
              <a routerLink="/organization/templates" routerLinkActive="active"
                >Templates</a
              >
              <a
                *ngIf="this.me?.is_admin"
                routerLink="/organization/contact-info-fields"
                routerLinkActive="active"
                >Contact Info Fields</a
              >
            </div>
          </div>

          <div class="ht-nav-item" *ngIf="!me?.is_agent">
            <div
              class="ht-nav-btn"
              routerLinkActive="active"
              routerLink="/upgrades"
            >
              <i class="fa-solid fa-puzzle-piece"></i>
              <label>Upgrades</label>
            </div>
            <div class="ht-nav-list">
              <a routerLink="/upgrades/addons" routerLinkActive="active">
                Add-ons
              </a>
            </div>
          </div>
        </div>

        <!--Menu sidebar bottom-->
        <div class="ht-nav-bottom">
          <!--Help item-->
          <div class="ht-nav-item">
            <a
              class="ht-nav-btn ht-nav-help"
              href="https://help.hubtype.com/"
              target="_blank"
              routerLinkActive="active"
            >
              <i class="fa-solid fa-circle-question"></i>
              <span *ngIf="!isCollapse">Documentation</span>
            </a>
            <app-version [hidden]="isCollapse"></app-version>
          </div>

          <div class="hrline"></div>
          <div class="ht-nav-item ht-nav-item-profile">
            <div class="ht-nav-btn">
              <app-avatar [pic]="me?.pic" [status]="me?.status"> </app-avatar>
              <div class="ht-nav-username">
                <div class="text-small no-wrap">{{ me?.name }}</div>
                <div class="text-small-extra no-wrap">({{ me?.roleName }})</div>
              </div>
              <div class="ht-nav-btn-arrows">
                <i class="fa-solid fa-angle-up"></i>
                <i class="fa-solid fa-angle-down"></i>
              </div>
            </div>
            <div class="ht-nav-list ht-nav-list-pulltop">
              <a routerLink="/profile" routerLinkActive="active">
                {{ me?.name }}</a
              >
              <a (click)="toggleAway()">
                {{
                  me?.status === 'status_away'
                    ? "Turn off 'Away' mode"
                    : "Turn on 'Away' mode"
                }}
                {{ me?.toggleStateName }}</a
              >
              <a (click)="logout()"> Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div role="right-side" class="right-side" cdk-scrollable>
      <ht-breadcrumb></ht-breadcrumb>

      <router-outlet></router-outlet>
      <pusher-status-handler></pusher-status-handler>
    </div>
  </div>
</ng-container>

<ng-template #withoutNavbar>
  <router-outlet></router-outlet>
</ng-template>
