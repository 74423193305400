import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  loggHttpError(requestMethod: string, responseError: HttpErrorResponse) {
    Sentry.withScope(scope => {
      scope.setFingerprint([
        requestMethod,
        responseError?.url,
        String(responseError?.status),
      ])
      Sentry.captureException(responseError?.message, {
        tags: {
          hubtypeApiError: true,
          hubtypeApiErrorCode: responseError?.status,
          hubtypeApiErrorUrl: this.convertToSentryMaxTagValue(
            responseError?.url
          ),
        },
        extra: {
          RequestMethod: requestMethod,
          HttpErrorResponse: responseError,
        },
      })
    })
  }

  private convertToSentryMaxTagValue(value: string): string {
    const TAG_VALUE_MAX_LENGTH = 200
    if (value?.length <= TAG_VALUE_MAX_LENGTH) {
      return value
    } else {
      return value?.slice(0, TAG_VALUE_MAX_LENGTH - 4) + '...'
    }
  }
}
